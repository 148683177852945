import React, { Component } from 'react';
import Mailchimp from 'react-mailchimp-form'



export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <div>
            Looks good? Happy to discuss: roman.jasko.uk@gmail.com
          </div>
          <p>
            &copy;2022 Roman Jasko (Romans Jasjko)
          </p>
          {/* <Mailchimp
        action='https://gmail.us16.list-manage.com/subscribe/post?u=f039b21597352430735484d34&amp;id=cb6152c95c'
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          }
        ]}
        /> */}
        </div>
      </div>
    </div>
  )
}



// https://www.npmjs.com/package/react-mailchimp-form